<template>
	<div class="content flex-space-between">
		<div class="left">
			<AsideNavMenu :list="navMenuList" title="通知公告" @navMenuClick="navMenuClick"></AsideNavMenu>
		</div>
		<div class="right">
			<router-view :index="navIndex"></router-view>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [
				{
					name: '最新发布',
					url: '/nolist'
				},
				{
					name: '竞赛/裁判员',
					url: '/nolist'
				},
				{
					name: '会议通知',
					url: '/nolist'
				},
				{
					name: '媒体通知',
					url: '/nolist'
				}
			],
			navIndex: 0
		}
	},

	mounted() {
		EventBus.$on('selectClick', (res) => {
			this.navIndex = res.index
		})
	},

	methods: {
		navMenuClick(index) {
			this.navIndex = index
			this.$route.meta.asideNavMenuIndex = index
			if (index == 0) {
				this.$route.meta.pathName = '最新发布'
			} else if (index == 1) {
				this.$route.meta.pathName = '竞赛/裁判员'
			} else if (index == 2) {
				this.$route.meta.pathName = '会议通知'
			} else if (index == 3) {
				this.$route.meta.pathName = '媒体通知'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

.right {
	flex: 1;
	margin-left: 20px;
	background: #fff;
	border-radius: 3px;
}
</style>
